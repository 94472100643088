/* global categorySectionComponent */
class CustomerCategorySectionComponent extends categorySectionComponent {

    getTemplate() {
        return ` <div class="row mx-auto px-5 filter-container">
                    <template v-for="filter of $store.getters.getItemFilters.filter((x)=>{return x.fields.FilterActived})">
                        <filterProxyComponent :filterField="filter.fields" :open_by_default="false"></filterProxyComponent>
                    </template>
                </div>`;
    }
}

CustomerCategorySectionComponent.registerComponent();
